.grid-card-container {
    .card {
        position: relative;
        border: none;
        cursor: pointer;

        .card-fav {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            color: #FFFFFF;
        }

        .card-img-top {
            aspect-ratio: 1/1;
            border-radius: 18px;
        }

        .card-body {
            position: relative;
            padding: 1rem 0.25rem;
            padding-bottom: 0px;

            .card-rating {
                position: absolute;
                top: 1rem;
                right: 0.25rem;
                font-size: 14px;
                height: 24px;
            }

            .card-title {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0.75rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                height: 24px;
            }

            .card-subtitle {
                font-size: 14px;
                font-weight: 400;
                color: #6c757d;
                margin-bottom: 0.5rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                height: 24px;
            }


            .card-text {
                font-size: 14px;
                margin-bottom: 0.5rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                height: 24px;
            }

            .card-price {
                font-size: 14px;
                margin-bottom: 0.5rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                height: 24px;
            }
        }
    }
}