.app {
    .page-container {
        margin-top: 68px;
    }

    .top-img-card {
        position: relative;

        img {
            border-radius: 18px;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
        }

        .top-img-text {
            border-radius: 18px;
            position: absolute;
            top: 0px;
            height: 100%;
            width: 100%;
        }
    }

    .btn-secondary {
        background-color: #8E8E92;
        border-color: #8E8E92;
        font-size: 16px;
        font-weight: 700;
        padding: 11px 20px;
        width: max-content;

        &:hover {
            background-color: #404040;
            border-color: #404040;
        }
    }

    .btn-primary {
        background-color: #556B2F;
        border-color: #556B2F;
        font-size: 16px;
        font-weight: 700;
        padding: 11px 20px;
        width: max-content;
        text-transform: uppercase;

        &:hover {
            background-color: #424C42;
            border-color: #424C42;
        }
    }

    .btn-link {
        background-color: transparent;
        border: transparent;
        font-size: 16px;
        font-weight: 700;
        padding: 11px 20px;
        width: max-content;
        color: #8E8E92;
        text-transform: uppercase;

        &:hover {
            color: #424C42;
        }
    }

    .modal-open {
        overflow: hidden;
    }

    .popup-component {
        .modal {
            background-color: #40404050;
            display: none;

            &.show {
                display: block;
            }

            .modal-dialog {
                margin-top: 0px;
                margin-bottom: 0px;
                top: 70px;
                max-height: calc(100vh - 70px);
                min-height: calc(100vh - 70px);
                height: calc(100vh - 70px);


                .modal-content {
                    border-radius: 10px 10px 0px 0px;
                    border-radius: 10px 10px 10px 10px;
                    max-height: calc(100vh - 70px);
                    min-height: calc(100vh - 70px);
                    height: calc(100vh - 70px);
                    background-color: #EBEBEB;

                    .modal-header {
                        border-bottom: 0px;

                        .modal-close-btn {
                            cursor: pointer;
                            border: 1px solid #404040;
                            border-radius: 20px;
                            padding: 5px;

                            &:hover {
                                background-color: #404040;
                                color: #FFFFFF;
                            }
                        }
                    }

                    .modal-footer {
                        justify-content: space-between;

                        .btn-secondary {
                            background-color: #8E8E92;
                            border-color: #8E8E92;
                            font-size: 16px;
                            font-weight: 700;
                            padding: 11px 20px;
                            width: max-content;

                            &:hover {
                                background-color: #404040;
                                border-color: #404040;
                            }
                        }

                        .btn-primary {
                            background-color: #556B2F;
                            border-color: #556B2F;
                            font-size: 16px;
                            font-weight: 700;
                            padding: 11px 20px;
                            width: max-content;

                            &:hover {
                                background-color: #424C42;
                                border-color: #424C42;
                            }
                        }

                        .btn-link {
                            background-color: transparent;
                            border: transparent;
                            font-size: 16px;
                            font-weight: 700;
                            padding: 11px 0px;
                            width: max-content;
                            color: #8E8E92;

                            &:hover {
                                color: #424C42;
                            }
                        }
                    }
                }
            }
        }
    }


    .skeleton-color {
        background: linear-gradient(90deg, #ddd, #EBEBEB, #ddd, #EBEBEB);
        animation: gradient 1.5s ease infinite;
        background-size: 400%;
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }
}