@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Raleway', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-justify {
  text-align: justify;
}

.material-icons{
  display: inline-flex;
  vertical-align: top;
}

.form-control:focus {
  border-color: #556B2F !important;
  box-shadow: 0 0 0 0.2rem rgba(85, 107, 47, .25) !important;
}